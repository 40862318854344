import React from "react";

function Posts({ handleClick, newslettersData }) {
  return (
    <section className="case-study-post-archive">
      <div className="container">
        <div className="row">
          {newslettersData
            .map(
              (data, index) =>
                data?.type === "case study" && (
                  <div className="case-study-post-grid" key={data.url}>
                    <a
                      href={data.url}
                      target={data.sameTab ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                    >
                      <article className="case-study-grid">
                        <div className="feature-image">
                          <img
                            alt={data.title}
                            src={
                              data.image.split(".")[1] !== "svg"
                                ? require(`../../../../assets/${data.image}`)
                                    .default
                                : require(`../../../../assets/${data.image}`)
                            }
                          />
                        </div>
                        <div className="case-study-content">
                          <div className="case-study-title">{data.title}</div>
                          <div className="case-study-description">
                            {data.content}
                          </div>
                          <div className="case-study-badge-cover">
                            <div className="case-study-badge">{data.type}</div>
                            <div className="case-study-status">
                              {data.status}
                            </div>
                          </div>
                        </div>
                      </article>
                    </a>
                  </div>
                )
            )
            .reverse()}
        </div>
      </div>
    </section>
  );
}

export default Posts;
