import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function BlogThirty() {
  return (
    <>
      <div className="blog-thirty-container">
        <MetaTags>
          <title>
            Transition Pathway Initiative (TPI): Streamlining the Low-Carbon
            Journey for businesses.
          </title>
          <meta
            name="description"
            content="In the sphere of sustainability and corporate accountability, the Transition Pathway Initiative (TPI) emerges as a consequential force, providing invaluable guidance to businesses as they navigate the complexities of transitioning to the circular economy."
          />
          <meta
            property="og:title"
            content="UpdaptCSR | Sustainability Platform"
          />
        </MetaTags>
        <div className="blog-title">
          Transition Pathway Initiative (TPI): Streamlining the Low-Carbon
          Journey for businesses.
        </div>

        <div className="blog-author-details">
          <div className="blog-author-right">
            <div className="blog-author-top">
              <div className="blog-author-name">Veenapani Joshi</div>
            </div>
            <div className="blog-author-bottom">
              <div className="blog-month">Feb 20, 2024</div>
              <div className="blog-read-time">3 min read</div>
            </div>
          </div>
        </div>

        <p>
          In the sphere of sustainability and corporate accountability, the
          Transition Pathway Initiative (TPI) emerges as a consequential force,
          providing invaluable guidance to businesses as they navigate the
          complexities of transitioning to the circular economy.
        </p>
        <p>
          The TPI methodology sets a high standard, embodying a profound
          commitment to objectivity, transparency, and global applicability. A
          closer examination of TPI's methodology unveils a robust framework
          strategically designed to assist businesses in this transformative
          journey.
        </p>
        <p>
          TPI adopts a two-dimensional approach, assessing companies based on
          publicly available information:
        </p>
        <div className="blog-picture">
          <img
            className="picture"
            src={require("../../../../../assets/Blogs/blog-30.png").default}
            alt="blog-30"
          />
        </div>
        <p>
          {" "}
          <b>Assessment of Management Quality:</b>
        </p>

        <p>
          TPI conducts a rigorous examination of companies' management quality,
          evaluating against meticulously defined indicators. This comprehensive
          assessment covers crucial facets such as company policy adherence,
          emissions reporting and verification practices, target alignment,
          strategic risk assessment, and executive remuneration. The
          multifaceted scrutiny ensures a thorough evaluation of a company's
          dedication to implementing sustainable business practices, with due
          consideration for ESG factors, including{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Reporting
          </a>{" "}
          and{" "}
          <a
            href="/solutions/esg-data-management"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Data management
          </a>
          .
        </p>
        <p>
          <b>Evaluation of Carbon Performance:</b>
        </p>
        <p>
          Utilizing modeling conducted by the International Energy Agency (IEA)
          for its Energy Technology Perspectives report, TPI meticulously
          evaluates companies' carbon performance. This model acts as the
          linchpin, translating international emissions targets into sectoral
          benchmarks using the Sectoral Decarbonization Approach. The resulting
          evaluation provides a nuanced perspective on companies' contributions
          to the low-carbon transition. This dual-layered assessment underscores
          TPI's commitment to providing a holistic and reflective evaluation of
          a company's environmental impact, integrating ESG considerations such
          as{" "}
          <a
            href="/solutions/esg-reporting"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Reporting
          </a>{" "}
          and{" "}
          <a
            href="/solutions/esg-data-management"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-content-href"
          >
            ESG Data management
          </a>
          .
        </p>
        <p>
          Beyond environmental metrics, TPI's evaluation of management quality
          extends to crucial strategic aspects. TPI offers insightful guidance
          for businesses aspiring to adopt circular economy principles by
          scrutinizing company policies, risk management protocols, and
          executive remuneration practices. This comprehensive approach ensures
          that TPI's assessments are a compass for organizations navigating the
          intricate landscape of circular economic transitions.
        </p>
      </div>
    </>
  );
}
export default BlogThirty;
