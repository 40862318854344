import React from "react";
import MetaTags from "react-meta-tags";
import NewBanner from "./NewBanner";
import UpdaptDescription from "./UpdaptDescription";
import Products from "./Products";
import Benefits from "./Benefits";
import Solutions from "./Solutions";
import Resources from "./Resources";
import GetDemoBanner from "./GetDemoBanner";
import GetFreeTrialBanner from "./GetFreeTrialBanner";
import Demo from "../../Demo";
import Clients from "./Clients";
import SecondaryDescription from "./SecondaryDescription";

function NewLandingPage({ productsList, resourceBlogs }) {
  return (
    <>
      <MetaTags>
        <title>
          ESG Software - Sustainability Data Management & Data Reporting
          Software Software
        </title>
        <meta
          name="description"
          content="Empower your sustainability journey with a SaaS technology that delivers seamless ESG data management and reporting solutions."
        />
        <meta
          property="og:title"
          content="ESG Software - Sustainability Data Management & Data Reporting Software"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <NewBanner />
      <UpdaptDescription />
      <Solutions />
      <SecondaryDescription />
      <div className="new-landing-page-slider">
        <Clients />
      </div>
      <Products productsList={productsList} />
      <GetDemoBanner />
      <Benefits />
      <GetFreeTrialBanner />
      <Resources resourceBlogs={resourceBlogs} />
      <Demo />
    </>
  );
}

export default NewLandingPage;
