import React from "react";
import Blogs from "../Blogs";
import Newsletter from "../Newsletter";
import Media from "../Media";
import CaseStudy from "../CaseStudy";
import Newsroom from "../Newsroom";
import { Route } from "react-router-dom";
import BlogOne from "../Blogs/BlogOne";
import BlogTwo from "../Blogs/BlogTwo";
import BlogThree from "../Blogs/BlogThree";
import BlogFour from "../Blogs/BlogFour";
import BlogFive from "../Blogs/BlogFive";
import BlogSix from "../Blogs/BlogSix";
import BlogSeven from "../Blogs/BlogSeven";
import BlogEight from "../Blogs/BlogEight";
import BlogNine from "../Blogs/BlogNine";
import BlogTen from "../Blogs/BlogTen";
import BlogEleven from "../Blogs/BlogEleven";
import BlogTwelve from "../Blogs/BlogTwelve";
import BlogThirteen from "../Blogs/BlogThirteen";
import BlogFourteen from "../Blogs/BlogFourteen";
import BlogFifteen from "../Blogs/BlogFifteen";
import BlogSixteen from "../Blogs/BlogSixteen";
import BlogSeventeen from "../Blogs/BlogSeventeen";
import BlogEighteen from "../Blogs/BlogEighteen";
import BlogNineteen from "../Blogs/BlogNineteen";
import BlogTwenty from "../Blogs/BlogTwenty";
import BlogTwentyOne from "../Blogs/BlogTwentyOne";
import NewsroomMediaOne from "../Newsroom/NewsroomMediaOne";
import NewsletterOne from "../Newsletter/NewsletterOne";
import BlogTwentyTwo from "../Blogs/BlogTwentyTwo";
import NewsletterTwo from "../Newsletter/NewsletterTwo";
import NewsletterThree from "../Newsletter/NewsletterThree";
import BlogTwentyThree from "../Blogs/BlogTwentyThree";
import Podcast from "../Podcast";
import PodcastInfoContainer from "../Podcast/containers/PodcastInfoContainer";
import BlogTwentyFour from "../Blogs/BlogTwentyFour";
import NewsletterFour from "../Newsletter/NewsletterFour";
import NewsletterFive from "../Newsletter/NewsletterFive";
import BlogTwentyFive from "../Blogs/BlogTwentyFive";
import NewsletterSix from "../Newsletter/NewsletterSix";
import BlogTwentySix from "../Blogs/BlogTwentySix";
import NewsletterSeven from "../Newsletter/NewsletterSeven";
import NewsletterEight from "../Newsletter/NewsletterEight";
import NewsletterNine from "../Newsletter/NewsletterNine";
import BlogTwentySeven from "../Blogs/BlogTwentySeven";
import NewsletterTen from "../Newsletter/NewsletterTen";
import NewsletterEleven from "../Newsletter/NewsletterEleven";
import BlogTwentyEight from "../Blogs/BlogTwentyEight";
import NewsletterTwelve from "../Newsletter/NewsletterTwelve";
import NewsletterThirteen from "../Newsletter/NewsletterThirteen";
import CaseStudyOne from "../CaseStudy/CaseStudyOne";
import CaseStudyTwo from "../CaseStudy/CaseStudyTwo";
import CaseStudyThree from "../CaseStudy/CaseStudyThree";
import CaseStudyFour from "../CaseStudy/CaseStudyFour";
import CaseStudyFive from "../CaseStudy/CaseStudyFive";
import CaseStudySix from "../CaseStudy/CaseStudySix";
import CaseStudySeven from "../CaseStudy/CaseStudySeven";
import NewsletterFourteen from "../Newsletter/NewsletterFourteen";
import BlogTwentyNine from "../Blogs/BlogTwentyNine";
import NewsletterFifteen from "../Newsletter/NewsletterFifteen";
import NewsletterSixteen from "../Newsletter/NewsletterSixteen";
import CaseStudyEight from "../CaseStudy/CaseStudyEight";
import NewsletterSeventeen from "../Newsletter/NewsletterSeventeen";
import BlogThirty from "../Blogs/BlogThirty";
import NewsletterEighteen from "../Newsletter/NewsletterEighteen";
import BlogThirtyOne from "../Blogs/BlogThirtyOne";
import NewsletterNineteen from "../Newsletter/NewsletterNineteen";
import BlogThirtyTwo from "../Blogs/BlogThirtyTwo";
import NewsletterTwenty from "../Newsletter/NewsletterTwenty";
import BlogThirtyThree from "../Blogs/BlogThirtyThree";
import NewsletterTwentyOne from "../Newsletter/NewsletterTwentyOne";
import CaseStudyNine from "../CaseStudy/CaseStudyNine";
import NewsletterTwentyTwo from "../Newsletter/NewsletterTwentyTwo";
import NewsroomMediaTwo from "../Newsroom/NewsroomMediaTwo";
import BlogThirtyFour from "../Blogs/BlogThirtyFour";
import NewsletterTwentyThree from "../Newsletter/NewsletterTwentyThree";

function Resource({ newslettersData, mediaData, podcastData }) {
  return (
    <>
      <Route path="/resource/blogs" exact>
        <Blogs blogsData={newslettersData} />
      </Route>
      <Route path="/resource/newsletter" exact>
        <Newsletter newslettersData={newslettersData}></Newsletter>
      </Route>
      <Route path="/resource/media" exact>
        <Media mediaData={mediaData}></Media>
      </Route>
      <Route path="/resource/newsroom" exact>
        <Newsroom mediaData={mediaData}></Newsroom>
      </Route>
      <Route path="/resource/podcast" exact>
        <Podcast podcastData={podcastData}></Podcast>
      </Route>
      <Route path="/resource/case-study" exact>
        <CaseStudy newslettersData={newslettersData}></CaseStudy>
      </Route>
      {/* <Route path="/resource/podcast/:info" component={PodcastInfoContainer} exact /> */}
      <Route path="/resource/podcast/:info" exact>
        <PodcastInfoContainer />
        {/* <BlogOne></BlogOne> */}
      </Route>
      <Route path="/resource/blogs/what-do-net-zero-emission-targets-mean">
        <BlogOne></BlogOne>
      </Route>
      <Route path="/resource/blogs/the-need-for-a-sustainable-business-behavior">
        <BlogTwo></BlogTwo>
      </Route>
      <Route path="/resource/blogs/why-diversity-in-integral-to-a-sustainable-business">
        <BlogThree></BlogThree>
      </Route>
      <Route path="/resource/blogs/sustainability-and-the-pandemic">
        <BlogFour></BlogFour>
      </Route>
      <Route path="/resource/blogs/world-environment-day">
        <BlogFive></BlogFive>
      </Route>
      <Route path="/resource/blogs/sea-level-rise-and-it-s-impact">
        <BlogSix></BlogSix>
      </Route>
      <Route path="/resource/blogs/the-road-to-sustainable-development-how-the-sdgs-are-reinforced-in-india">
        <BlogSeven></BlogSeven>
      </Route>
      <Route path="/resource/blogs/what-happens-when-we-discard-our-phone-for-a-smarter-one">
        <BlogEight></BlogEight>
      </Route>
      <Route path="/resource/blogs/sustainability-and-the-fashion-industry">
        <BlogNine></BlogNine>
      </Route>
      <Route path="/resource/blogs/responsible-companies-and-a-responsible-nation-an-insight-to-csr-in-india">
        <BlogTen></BlogTen>
      </Route>
      <Route path="/resource/blogs/sustainability-let-s-make-it-better">
        <BlogEleven></BlogEleven>
      </Route>
      <Route path="/resource/blogs/sdgs-101">
        <BlogTwelve></BlogTwelve>
      </Route>
      <Route path="/resource/blogs/are-evs-the-way-of-the-future">
        <BlogThirteen></BlogThirteen>
      </Route>
      <Route path="/resource/blogs/debunking-the-myths">
        <BlogFourteen></BlogFourteen>
      </Route>
      <Route path="/resource/blogs/Understanding-the-greenhouse-gases">
        <BlogFifteen></BlogFifteen>
      </Route>
      <Route path="/resource/blogs/Let's-talk-about-Carbon-Taxes">
        <BlogSixteen></BlogSixteen>
      </Route>
      <Route path="/resource/blogs/Understanding-the-Greenhouse-Gases-CH4">
        <BlogSeventeen></BlogSeventeen>
      </Route>
      <Route path="/resource/blogs/Extreme-weather-events-and-how-they-are-linked-to-Climate-Change">
        <BlogEighteen></BlogEighteen>
      </Route>
      <Route path="/resource/blogs/understanding-climate-justice">
        <BlogNineteen></BlogNineteen>
      </Route>
      <Route path="/resource/blogs/let's-talk-about-biofuels">
        <BlogTwenty />
      </Route>
      <Route path="/resource/blogs/where-does-the-world-stand-with-sdg-2030-today">
        <BlogTwentyOne />
      </Route>
      <Route path="/resource/newsroom/dnv-scpa-collaborates-with-updapt-an-esg-tech-co-to-transform-your-sustainability-journey-230553">
        <NewsroomMediaOne />
      </Route>
      <Route path="/resource/newsletter/october2022Newsletter" exact>
        <NewsletterOne />
      </Route>
      <Route
        path="/resource/blogs/importance-of-an-esg-report-for-a-better-esg-management"
        exact
      >
        <BlogTwentyTwo />
      </Route>
      <Route path="/resource/newsletter/november2022Newsletter" exact>
        <NewsletterTwo />
      </Route>
      <Route path="/resource/blogs/how-can-we-achieve-decarbonization" exact>
        <BlogTwentyThree />
      </Route>
      <Route path="/resource/newsletter/december2022Newsletter" exact>
        <NewsletterThree />
      </Route>
      <Route path="/resource/blogs/benefits-of-impact-investment" exact>
        <BlogTwentyFour />
      </Route>
      <Route path="/resource/newsletter/january2023Newsletter" exact>
        <NewsletterFour />
      </Route>
      <Route path="/resource/newsletter/february2023Newsletter" exact>
        <NewsletterFive />
      </Route>
      <Route path="/resource/blogs/internal-carbon-pricing" exact>
        <BlogTwentyFive />
      </Route>
      <Route path="/resource/newsletter/March2023Newsletter" exact>
        <NewsletterSix />
      </Route>
      <Route
        path="/resource/blogs/different-terms-but-the-goal-is-lets-go-net-zero"
        exact
      >
        <BlogTwentySix />
      </Route>
      <Route path="/resource/newsletter/april2023Newsletter" exact>
        <NewsletterSeven />
      </Route>
      <Route path="/resource/newsletter/may2023Newsletter" exact>
        <NewsletterEight />
      </Route>
      <Route path="/resource/newsletter/june2023Newsletter" exact>
        <NewsletterNine />
      </Route>
      <Route path="/resource/blogs/the-importance-of-integrating-dei" exact>
        <BlogTwentySeven />
      </Route>
      <Route path="/resource/newsletter/july2023Newsletter" exact>
        <NewsletterTen />
      </Route>
      <Route path="/resource/newsletter/august2023Newsletter" exact>
        <NewsletterEleven />
      </Route>
      <Route
        path="/resource/blogs/updapt-esg-all-in-One-sustainability-software"
        exact
      >
        <BlogTwentyEight />
      </Route>
      <Route path="/resource/newsletter/september2023Newsletter" exact>
        <NewsletterTwelve />
      </Route>
      <Route path="/resource/newsletter/october2023Newsletter" exact>
        <NewsletterThirteen />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-cement-companies"
        exact
      >
        <CaseStudyOne />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-healthcare-sector"
        exact
      >
        <CaseStudyTwo />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-pharma-companies"
        exact
      >
        <CaseStudyThree />
      </Route>
      <Route path="/resource/case-study/esg-reporting-for-energy-sector" exact>
        <CaseStudyFour />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-real-estate-sector"
        exact
      >
        <CaseStudyFive />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-supplier-assessment"
        exact
      >
        <CaseStudySix />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-waste-management-companies"
        exact
      >
        <CaseStudySeven />
      </Route>
      <Route path="/resource/newsletter/november2023Newsletter" exact>
        <NewsletterFourteen />
      </Route>
      <Route
        path="/resource/blogs/details-about-esg-data-management-software-for-2024"
        exact
      >
        <BlogTwentyNine />
      </Route>{" "}
      <Route path="/resource/newsletter/december2023Newsletter" exact>
        <NewsletterFifteen />
      </Route>
      <Route path="/resource/newsletter/january2024Newsletter" exact>
        <NewsletterSixteen />
      </Route>{" "}
      <Route path="/resource/case-study/esg-reporting-for-fmcg-sector" exact>
        <CaseStudyEight />
      </Route>
      <Route path="/resource/newsletter/february2024Newsletter" exact>
        <NewsletterSeventeen />
      </Route>
      <Route path="/resource/blogs/transition-pathway-initiative" exact>
        <BlogThirty />
      </Route>{" "}
      <Route path="/resource/newsletter/march2024Newsletter" exact>
        <NewsletterEighteen />
      </Route>{" "}
      <Route
        path="/resource/blogs/esg-software-for-small-businesses-in-2024-updapt"
        exact
      >
        <BlogThirtyOne />
      </Route>{" "}
      <Route path="/resource/newsletter/april2024" exact>
        <NewsletterNineteen />
      </Route>{" "}
      <Route
        path="/resource/blogs/choose-wisely-esg-and-sustainability-reporting-software-for-your-business"
        exact
      >
        <BlogThirtyTwo />
      </Route>{" "}
      <Route path="/resource/newsletter/may2024" exact>
        <NewsletterTwenty />
      </Route>
      <Route
        path="/resource/blogs/updapt-esg-reporting-software-for-real-estate-portfolio-and-investors"
        exact
      >
        <BlogThirtyThree />
      </Route>{" "}
      <Route path="/resource/newsletter/june2024" exact>
        <NewsletterTwentyOne />
      </Route>
      <Route
        path="/resource/case-study/esg-reporting-for-facility-management"
        exact
      >
        <CaseStudyNine />
      </Route>
      <Route path="/resource/newsletter/july2024" exact>
        <NewsletterTwentyTwo />
      </Route>
      <Route
        path="/resource/newsroom/updapt-an-esg-tech-co-partners-with-grant-thornton-advisory-east-africa"
        exact
      >
        <NewsroomMediaTwo />
      </Route>
      <Route
        path="/resource/blogs/esg-software-for-manufacturing-industries"
        exact
      >
        <BlogThirtyFour />
      </Route>
      <Route path="/resource/newsletter/august2024" exact>
        <NewsletterTwentyThree />
      </Route>
    </>
  );
}
export default Resource;
