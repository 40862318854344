import React, { useState } from "react";
import Clients from "../component/Clients";
import assets from "../../../../shared/constants/assetVariables";

function ClientsContainer() {
  const [clientDetails] = useState([
    {
      clientName: "TATA Power",
      clientLogo: assets.images.tatapower,
      ClientDesc:
        "At Tata Power, our commitment to sustainable practices and adherence to ESG principles is unwavering. With the ESG Dashboard provided by Updapt, we have a powerful tool at our fingertips, enabling us to monitor and visualize key environmental metrics in real-time. From tracking our carbon footprint to managing energy consumption and automating GHG accounting, this dashboard provides invaluable insights that drive our sustainability efforts forward. \n One of the standout features of Updapt's dashboard is its Peer Analysis capability, which allows us to benchmark our ESG performance against industry peers. This not only helps us identify areas for improvement but also sheds light on best practices to emulate. The interactive user interface and collaborative features foster enhanced communication and teamwork within our organization, ensuring everyone is aligned towards our sustainability goals. \n Moreover, Updapt seamlessly integrates with our existing processes and tools, streamlining our sustainability initiatives and maximizing efficiency. With actionable insights and peer comparisons at our disposal, we are empowered to make informed decisions that propel Tata Power towards a greener and more sustainable future.",
      clientIcon: assets.images.tataPowerTestimonialIcon,
      authorName: "Dr. Ivaturi Nandikeswara Rao",
      authorDesc: "Head Environment & Climate Change at TATA Power",
    },
    {
      clientName: "Apollo Hospitals Group",
      clientLogo: assets.images.apollo,
      ClientDesc:
        "Integrating Updapt's digital ESG tool at Apollo Hospitals has had a significant impact on our sustainability practices. We have created an extensive emissions inventory that has helped put targets to reduce our carbon footprint. The tool's user-friendly features have made it easier for us to track, monitor, and manage our ESG Data, reporting according to global standard frameworks and audit management. Features like Peer Analysis, and Predictive Analysis tools have been useful in providing practical insights to track our progress, compare ourselves to industry peers, and prepare for future challenges.",
      clientIcon: assets.images.apolloHospitalsTestimonialIcon,
      authorName: "Dr. Karan Thakur",
      authorDesc: "Group Lead - Sustainability & ESG at Apollo Hospitals Group",
    },
    {
      clientName: "Cipla",
      clientLogo: assets.images.cipla,
      ClientDesc:
        "Digital ESG Solution of Updapt is enabling Cipla in achieving Sustainability Goals to be a Carbon Neutral, Water Neutral and Zero Waste to Landfill company. The solution is robust and agile wherein we are able to manage our ESG data lifecycle for our global operations and sites in a seamless manner along with automated GHG accounting. The dashboards are sharp for the Management team to quickly comprehend and monitor key ESG performance metrics along with auditable and complete Sustainability reports well aligned to standards such as GRI, BRSR, etc.",
      clientIcon: assets.images.profile,
      authorName: "Sharad Kalghatgi",
      authorDesc:
        "Global Vice President & Group Head EHS & Sustainability, Cipla",
    },
    {
      clientName: "Licious",
      clientLogo: assets.images.licious,
      ClientDesc:
        "SaaS based ESG solution of Updapt is being used by Licious to manage the ESG operations efficiently in terms of data management across our units, carbon footprint accounting and performance monitoring towards our net-zero goals. The tool is easy to use by our team members across various sites supported by a quick responsive team of Updapt for any handholding. ESG performance reports and MIS generated through the digital solution of Updapt supports the Sustainability team in internal management discussions and also our engagements with other stakeholders such as Investors, Board, etc.",
      clientIcon: assets.images.img2,
      authorName: "Rajesh Kumaramenon",
      authorDesc: "Chief Quality and Sustainability Officer, Licious",
    },
    {
      clientName: "Pricol ",
      clientLogo: assets.images.pricol,
      ClientDesc:
        "Updapt ESG solution is helping Pricol calculate the organisations overall carbon footprint in a fully digitised and seamless manner along with advanced analytics and interactive dashboards. The solutions easy to use interface & strong computational capability have helped manage our ESG metrics effectively while also facilitating Sustainability reporting in alignment with BRSR & GRI standards.",
      clientIcon: assets.images.pricolIcon,
      authorName: "T.G Thamizhanban",
      authorDesc: "Company Secretary, Pricol",
    },
  ]);
  const [index, setIndex] = useState(0);
  const [characterCount, setCharacterCount] = useState(
    clientDetails[0].ClientDesc.length
  );
  const [readMoreBool, setReadMoreBool] = useState(false);

  const clientLogos = [
    "tatapower.svg",
    "tolaram.svg",
    "emrill.svg",
    "sbi_card.svg",
    "iifl_finance.svg",
    "dominos.svg",
    "skf.svg",
    "rhi_magnesita.svg",
    "burgerking.svg",
    "zomato.svg",
    "shapoorji_pallonji_logo.svg",
    "cipla.svg",
    "sunpharma.svg",
    "hcl.svg",
    "amiOrganicsLogo.svg",
    "birlaGroupLogo.svg",
    "cumiLogo.svg",
    "epsilonLogo.svg",
    "narayanaHealthLogo.svg",
    "supremeLogo.svg",
    "sutherlandLogo.svg",
    "suzlonLogo.svg",
    "everstone.svg",
    "cochinshipyard.svg",
    "pricol.svg",
    "cookieman.svg",
    "lodha.svg",
    "fnb_asia.svg",
    "cpc.svg",
    "dsg.svg",
    "everenviro.svg",
    "everise.svg",
    "everlife.svg",
    "everlife-1.svg",
    "eversource.svg",
    "greencell.svg",
    "indospace.svg",
    "indostar.svg",
    "infostretch.svg",
    "jubilant.svg",
    "kathari.svg",
    "lakshmimachineworks.svg",
    "licious.svg",
    "lithium.svg",
    "omega_healthcare.svg",
    "pgp_glass.svg",
    "radiance.svg",
    "re_sustainability.svg",

    "sahyadri.svg",
    "sajjan_india.svg",
    "servion.svg",
    "sjs.svg",
    "slayback.svg",
    "softgel.svg",
    "sohanlal.svg",
    "sulochana.svg",
    "translumina.svg",

    "api_holdings.svg",
    "apollohospitals.svg",
    "ayana.svg",
    "bitmore.svg",
    "calibre.svg",
    "cgpower.svg",
    "chemopharm.svg",

    "lupin.svg",

    "ramco.svg",
    "ghcl.svg",
    "ceat.svg",
    "multiples.svg",
    "republicBank.svg",
    "refex.svg",
    "zee.svg",
    "deepak_nitrite_logo.svg",
    "everest_industrial_logo.svg",
    "harrys_logo.svg",
    "lloyd_metals.svg",
    "mastek.svg",
    "nuvoco_logo.svg",
    "protean_logo.svg",
    "radiaant_logo.svg",
    "jKCements.svg",
    "kuantumPapers.svg",
    "ashokLeyland.svg",
    "gokaldasExports.svg",
    "grInfra.svg",
    "igarashiMotors.svg",
    "bluePlanet.svg",
    "escortsKubota.svg",
    "indoramaCorporation.svg",
    "lTFinance.svg",
  ];

  const handleNextButton = () => {
    setReadMoreBool(false);
    if (index < clientDetails.length - 1) {
      setIndex(1 + index);
      setCharacterCount(clientDetails[1 + index].ClientDesc.length);
    }
  };
  const handleBackButton = () => {
    setReadMoreBool(false);
    if (index > 0) {
      setIndex(index - 1);
      setCharacterCount(clientDetails[index - 1].ClientDesc.length);
    }
  };
  const handleReadMore = () => {
    setReadMoreBool(!readMoreBool);
  };

  return (
    <Clients
      clientDetails={clientDetails}
      index={index}
      handleBackButton={handleBackButton}
      handleNextButton={handleNextButton}
      clientLogos={clientLogos}
      characterCount={characterCount}
      handleReadMore={handleReadMore}
      readMoreBool={readMoreBool}
    />
  );
}
export default ClientsContainer;
