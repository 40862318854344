import React from "react";

function NewLandingPageFeature() {
  return (
    <div className="new-landing-page-get-demo-feature">
      <h1 className="demo-title">Get a Demo</h1>
      <p className="demo-description ">
        UpdaptESG is a comprehensive tool that helps businesses to manage and
        monitor a company's ESG target effectively and helps in generating ESG
        reporting that aligns with global sustainability reporting standards and
        our customer-centric approach can assist your company to execute
        sustainability plans and implementation effortlessly.
      </p>

      <ul className="solution-feature-menu">
        <span className="solution-feature-menu-heading">Why UpdaptESG</span>
        <li className="solution-feature-menu-list">
          Cost Effective and 18x faster solutions.
        </li>
        <li className="solution-feature-menu-list">
          Robust and Agile Tech architecture with quick deployment.
        </li>
        <li className="solution-feature-menu-list">
          Proven success stories with reputed clients and partners.
        </li>
      </ul>
      <div className="address-container">
        <div className="title">Address: </div>
        <div className="address-content">
          <p>Updapt ESG IT LLC, Dubai, UAE</p>
          <p>104-E1, Mohd Hamed saif Alrumhi</p>
          <p>Building, AI Muteena, Dubai, UAE</p>
        </div>
      </div>
    </div>
  );
}

export default NewLandingPageFeature;
