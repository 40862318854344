import React from "react";
import "react-notifications/lib/notifications.css";
import spinner from "../../../assets/FooterImages/spinner.gif";
function Subscribe({
  errors,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  isAlert,  successStatus,setSuccessStatus,formName
}) {
  return (
    <form
    className= {"newsletter-form-travel "+formName}
  
  >
    <div className={successStatus ? `newsletter-inner show` : "newsletter-inner" } >
      <input
        type="email"
        name="email"
        className="form-control"
        id="email"
        placeholder="Enter your Email"
        value={formObj.email}
          onChange={handleTextOnChange}
      />
      
      <button
        type="submit"
        name="submit"
        id="newsletter-submit"
        className="newsletter-submit"
        onClick={handleSendRequest}
        style={{background : isAlert && "#2159b0"}}
      >
        <span className="btn-text">{isAlert ? 
         <img src={spinner} alt="this slowpoke moves" className="btn-spinner"/> : 
         "subscribe"}</span>
       

      </button>
    </div>
    {errors.email && 
    <p className="error">{errors.email}</p>
    }
        {errors.warning && 
    <p className="error">{errors.warning}</p>
    }
    {successStatus && 
      <div className="success-box">
        <span>Thank you for subscribing. </span>
        <span  className="close" onClick= {()=> setSuccessStatus(false)}>&times;</span></div>
      }
    
  </form>
  );
}

export default Subscribe;
