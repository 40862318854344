import React from "react";

function Benefits() {
  const benefits = [
    {
      id: "1",
      value: "100%",
      description: "Accurate & Complete ESG Data",
      bgcolor: "#E1E9FF",
    },
    {
      id: "2",
      value: "7000+",
      description: "Human Hours Saved per year",
      bgcolor: "#ECF1FF",
    },
    {
      id: "3",
      value: "Over 91%",
      description: "Time Saving on Audit Management & Reporting",
      bgcolor: "#E1E9FF",
    },
    {
      id: "4",
      value: "20%+",
      description: "Carbon Intensity Reduction",
      bgcolor: "#ECF1FF",
    },
    {
      id: "5",
      value: "12%+",
      description: "Reduction in Hazardous Waste",
      bgcolor: "#E1E9FF",
    },
  ];
  return (
    <div className="benefits-outer-container">
      <div className="benefits-inner-container">
        <p className="benefit-heading">
          Empower your ESG strategies with robust compliance & High ROI through
          Updapt tools.
        </p>
        <div className="benefit-card-container">
          {benefits?.map((benefit) => {
            return (
              <div
                className="benefit-card"
                style={{ backgroundColor: benefit?.bgcolor }}
                key={benefit.id}
              >
                <p className="count">{benefit?.value}</p>
                <p className="description">{benefit?.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Benefits;
