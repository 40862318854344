import React from "react";
import { Route } from "react-router-dom";
import DataManagement from "../DataManagement";
import Analytics from "../Analytics";
import Grant from "../Grant";
import Reporting from "../Reporting";
import NetZero from "../NetZero";
import EnterpriseRiskManagement from "../EnterpriseRiskManagement";
import SupplierAssessment from "../SupplierAssessment";
import PortfolioAssessment from "../PortfolioAssessment";

function Products() {
  // const history = useHistory();
  // let path = window.location.href;
  //## commented because redirect moved to server code. can be removed if its working
  // if (path.includes("/solutions/reporting")) {
  //   history.replace("/solutions/esg-reporting");
  // } else
  // if (path.includes("/solutions/analytics")) {
  //   history.replace("/solutions/esg-analytics");
  // } else if (path.includes("/solutions/data-management")) {
  //   history.replace("/solutions/esg-data-management");
  // }
  return (
    <>
      <Route path="/solutions/esg-data-management" exact>
        <DataManagement></DataManagement>
      </Route>
      <Route path="/solutions/data-management" exact>
        <DataManagement></DataManagement>
      </Route>
      <Route path="/solutions/esg-analytics" exact>
        <Analytics></Analytics>
      </Route>
      <Route path="/solutions/esg-reporting" exact>
        <Reporting></Reporting>
      </Route>
      <Route path="/solutions/netzero" exact>
        <NetZero></NetZero>
      </Route>
      <Route path="/solutions/enterprise-risk-management" exact>
        <EnterpriseRiskManagement></EnterpriseRiskManagement>
      </Route>
      <Route path="/solutions/supplier-assessment" exact>
        <SupplierAssessment></SupplierAssessment>
      </Route>
      <Route path="/solutions/portfolio-assessment" exact>
        <PortfolioAssessment />
      </Route>
      <Route path="/solutions/grant" exact>
        <Grant></Grant>
      </Route>
    </>
  );
}

export default Products;
