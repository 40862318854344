import React from "react";
import "../SCSS/styles.css";
import { NavLink } from "react-router-dom";
function NewBanner() {
  return (
    <section>
      <div className="banner-outer-container">
        <div className="banner-heading-container">
          <p className="banner-heading">ESG Data Management & Reporting Tool</p>
          <p className="banner-heading">for businesses in the Middle East</p>
          <p className="banner-subheading">
            Your All-in-One Solution for Effortless tracking, monitoring,
          </p>
          <p className="banner-subheading">
            and managing your Corporate Sustainability Journey.
          </p>
          <NavLink to="/demo" className="banner-demo-btn">
            Get a demo
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default NewBanner;
