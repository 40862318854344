import React from "react";
import "../SCSS/styles.css";
import MetaTags from "react-meta-tags";
import CaseStudyDownloadModal from "../../CaseStudyDownloadModal";

function CaseStudyNine({
  caseStudyData,
  downloadModalBool,
  setDownloadModalBool,
}) {
  return (
    <>
      <MetaTags>
        <title>Facility Management case study</title>
        <meta name="description" content={caseStudyData.content} />
        <meta
          property="og:title"
          content="UpdaptCSR | Sustainability Platform"
        />
      </MetaTags>
      <div className="case-study-nine-main-container">
        <div className="case-study-header-container">
          <div className="title">{caseStudyData.title}</div>
        </div>
        <div className="case-study-content-main-container">
          <div className="image-cover">
            <img
              className="picture"
              src={
                require(`../../../../../assets/images/${caseStudyData.img}`)
                  .default
              }
              alt="thumbnail"
            />
          </div>
          <div className="content-container">
            <div className="content">
              {caseStudyData.content}
              <div className="shadow"></div>
            </div>
            <div className="download-for-full-content">
              Download the case study to continue reading
            </div>
            <div className="button-container">
              <div
                className="button"
                onClick={() => setDownloadModalBool(true)}
              >
                Download Case Study
              </div>
            </div>
          </div>
        </div>
      </div>
      <CaseStudyDownloadModal
        caseStudyData={caseStudyData}
        downloadModalBool={downloadModalBool}
        setDownloadModalBool={setDownloadModalBool}
      />
    </>
  );
}

export default CaseStudyNine;
