import React from "react";
import "../SCSS/styles.scss";

import Intro from "./Intro";
import Subscribe from "./Subscribe";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";

function Newsletter({ newslettersData }) {
  return (
    <>
      <MetaTags>
        <title>Newsletter | Updapt</title>
        <meta
          name="description"
          content="Keep up with the latest ESG news and climate change insights."
        />
        <meta property="og:title" content="Newsletter | Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro />

      <Posts newslettersData={newslettersData} />

      <Subscribe />
    </>
  );
}
export default Newsletter;
