import React from "react";
import "../SCSS/styles.scss";
import Intro from "./Intro";
import Posts from "./Posts";
import MetaTags from "react-meta-tags";

function CaseStudy({ newslettersData }) {
  return (
    <>
      <MetaTags>
        <title>Case Study | Updapt</title>
        <meta
          name="description"
          content="Transforming Sustainability Challenges into Success Stories"
        />
        <meta property="og:title" content="Case Study | Updapt" />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>

      <Intro />

      <Posts newslettersData={newslettersData} />
    </>
  );
}
export default CaseStudy;
