import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "../SCSS/styles.css";

function CaseStudyDownloadModal({
  caseStudyData,
  downloadModalBool,
  setDownloadModalBool,
  handleChange,
  handleDownload,
  setCustomerData,
}) {
  return (
    <>
      <Dialog
        className="case-study-download-modal-container"
        open={downloadModalBool}
        onClose={() => {
          setDownloadModalBool(false);
          setCustomerData({ name: "", email: "" });
        }}
        PaperProps={{
          style: {
            maxWidth: "1024px",
            width: "60em",
            maxHeight: "510px",
          },
        }}
      >
        <DialogTitle className="case-study-download-modal-header">
          <div className="case-study-download-modal-title">
            <div className="title">DOWNLOAD CASE STUDY</div>
            <img
              onClick={() => {
                setDownloadModalBool(false);
                setCustomerData({ name: "", email: "" });
              }}
              src={require("../../../../../assets/images/moduleCloseButton.svg")}
              alt="moduleCloseButton"
            />
          </div>
        </DialogTitle>
        <DialogContent className="case-study-download-modal-content">
          <div className="case-study-download-modal-left-container">
            <div className="title">{caseStudyData.title}</div>
            <img
              src={
                require(`../../../../../assets/images/${caseStudyData.img}`)
                  .default
              }
              alt="thumbnail"
            />

            {/* <div className="content">{caseStudyData.content}</div> */}
          </div>
          <div className="case-study-download-modal-right-container">
            <div className="title">Just Another Small Step</div>
            <div className="sub-title">
              Please fill out the following details to download the file{" "}
            </div>
            <div className="input-cover">
              <div className="input-title">Your Name</div>
              <input
                type="text"
                placeholder="Please enter the name"
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </div>
            <div className="input-cover">
              <div className="input-title">Email ID</div>
              <input
                type="text"
                placeholder="Please enter the email"
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </div>
            <div className="download-button" onClick={(e) => handleDownload(e)}>
              Download Now
            </div>
          </div>
        </DialogContent>
      </Dialog>{" "}
      <NotificationContainer />
    </>
  );
}

export default CaseStudyDownloadModal;
