import React from "react";
import "../SCSS/styles.css";

function Solutions() {
  return (
    <div className="solutions-outer-container">
      <section className="feature-info-container">
        <p className="feature-heading">Key Features</p>
        <p className="feature-description">
          Unleash Data into Reliable Insights and streamline ESG Reporting
          Effortlessly with UpdaptESG
        </p>
      </section>
      <section className="solution-container-right-image">
        <div className="solution-contents">
          <p className="solution-heading">
            Smart Emission Calculation with{" "}
            <a
              href="/solutions/netzero"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-page-content-href"
            >
              emissions management software
            </a>
          </p>
          <p className="solution-explanation">
            Our advanced algorithms provide a meticulous calculation of your
            carbon and greenhouse gas emissions, ensuring accuracy in reporting
            across Scope 1, 2, and 3.
          </p>
          <p className="solution-explanation">
            Dive deep into your sustainability journey with granular data
            tracking at each site, business unit, and product level, allowing
            for comprehensive insights and targeted improvements.
          </p>
        </div>
        <div className="solution-image">
          <img
            src={require("../../../assets/landing/Smart Emission Calculation.svg")}
          />
        </div>
      </section>

      <section className="solution-container-left-image">
        <div className="solution-image">
          <img
            src={require("../../../assets/landing/Global Reporting Standard.svg")}
          />
        </div>
        <div className="solution-contents">
          <p className="solution-heading">
            Global Reporting Standard Alignment
          </p>
          <p className="solution-explanation">
            UpdaptESG seamlessly integrates with global standards, including
            GRI, TCFD, CDP, CSRD, ESRS and more ensuring that your reporting is
            compliant while reflecting your commitment to best practices.
          </p>
          <p className="solution-explanation">
            Tailor your reports effortlessly with a simple click, allowing the
            teams to get a custom report aligned with their desired framework,
            providing flexibility and ease of use.
          </p>
        </div>
      </section>

      <section className="solution-container-right-image">
        <div className="solution-contents">
          <p className="solution-heading">AI & ML Driven Analytics</p>
          <p className="solution-explanation">
            Compare your performance with industry peers in terms of metrics and
            strategic decision-making, fostering healthy competition and growth
            with peer analysis feature.
          </p>
          <p className="solution-explanation">
            Anticipate future trends and challenges with our predictive{" "}
            <a
              href="/solutions/esg-analytics"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-page-content-href"
            >
              ESG analysis tool
            </a>
            , allowing you to proactively address sustainability issues before
            they arise.
          </p>
        </div>
        <div className="solution-image">
          <img
            src={require("../../../assets/landing/AI & ML Driven Analytics.svg")}
          />
        </div>
      </section>

      <section className="solution-container-left-image">
        <div className="solution-image">
          <img
            src={require("../../../assets/landing/Interactive Dashboards.svg")}
          />
        </div>
        <div className="solution-contents">
          <p className="solution-heading">Interactive Dashboards</p>
          <p className="solution-explanation">
            Visualize the environmental impact of your operations with
            intensity-based performance comparisons, enabling you to identify
            areas for improvement.
          </p>
          <p className="solution-explanation">
            Stay on track with real-time insights into target vs actual
            performance, facilitating informed decision-making and strategic
            planning.
          </p>
        </div>
      </section>

      <section className="solution-container-right-image">
        <div className="solution-contents">
          <p className="solution-heading">
            Enterprise level collaboration & Dynamic APIs
          </p>
          <p className="solution-explanation">
            Drill down to site/plant/location level performance comparisons,
            offering a comprehensive view of your organization's sustainability
            efforts.
          </p>
          <p className="solution-explanation">
            UpdaptESG features customized integration with existing systems, a
            dedicated API integration server, and fully secure REST APIs. It
            supports multi-technology integration including API, FTP, Database
            fetch, XML.
          </p>
        </div>
        <div className="solution-image">
          <img
            src={require("../../../assets/landing/Enterprise Level Collaboration.svg")}
          />
        </div>
      </section>
    </div>
  );
}

export default Solutions;
