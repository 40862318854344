import React from "react";
import assets from "../../../shared/constants/assetVariables";
import { Link, withRouter } from "react-router-dom";

function Solution() {
  return (
    <section className="home-solution-section" id="our-products-section">
      <div className="circle-1">
        <div className="circle-2"></div>
        <div className="circle-rotate-1"></div>
        <div className="circle-rotate-2"></div>
      </div>
      <div className="home-solution-container">
        <div className="home-solution-content">
          <h2 className="home-solution-title">
            Easy-to-use platform that caters to all your ESG needs
          </h2>
          <p className="home-solution-description">
            Enhance your company's sustainability performance with a SaaS tool
            that provides <br />
            automated reporting, management and deep analytics for ESG data.
          </p>
        </div>

        <div className="home-solution-datatrack-wrapper">
          <div className="home-solution-datatrack-content-wrapper">
            {/* <div className="datatrack-grey-circle"></div> */}
            <div className="home-solution-datatrack-content">
              <div className="home-solution-datatrack-badge">
                DATA MANAGEMENT
              </div>
              <h3 className="home-solution-datatrack-title">
                {/* Effortlessly Collect and Track ESG Data */}
                ESG Data Management Platform
              </h3>
              <p className="home-solution-datatrack-description">
                Track, analyze, and consolidate your ESG data using a
                centralized data collection and management system for better
                decision-driven analytics and value creation.
              </p>
              <div className="home-solution-datatrack-button">
                <Link
                  to="solutions/esg-data-management"
                  className="home-solution-learn-btn"
                >
                  Learn More
                  <img
                    src={assets.icons.rightArrowIcon}
                    alt="updapt"
                    className="image-icon"
                    height="100%"
                    width="100%"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="home-solution-datatrack-image-wrapper">
            <div className="home-solution-datatrack-image">
              <img
                src={assets.images.homeDatatrackImage}
                alt="this slowpoke moves"
                className="datatrack-image"
                height="100%"
                width="100%"
              />
              <div className="blue-circle"></div>
            </div>
          </div>
        </div>

        <div className="home-solution-reporting-wrapper">
          <div className="home-solution-reporting-image-wrapper">
            <div className="home-solution-reporting-image">
              {/* <video
                className="reporting-image"
                width="100%"
                height="100%"
                loop={true}
                autoPlay="autoplay"
                muted
              >
                <source src={reporting} type="video/mp4" />
              </video> */}
              <img
                src={assets.images.reportinHomePageBanner.default}
                alt="reportinHomePageBanner"
              />
              <div className="home-circle-right">
                <div className="home-circle-right-2"></div>
                <div className="home-circle-rotate-1"></div>
                <div className="home-circle-rotate-2"></div>
              </div>
            </div>
          </div>
          <div className="home-solution-reporting-content-wrapper">
            <div className="home-solution-reporting-content">
              <div className="home-solution-reporting-badge">REPORTING</div>
              <h3 className="home-solution-reporting-title">
                {/* Create Maximum Transparency with ESG Reporting  */}
                Best ESG Reporting Software
                <br />
                <span></span>
              </h3>
              <p className="home-solution-reporting-description">
                Generate ESG reports aligned with internationally recognized
                sustainability frameworks such as GRI, CSRD, ESRS, TCFD, CDP,
                BRSR, IFRS, and others using the automated data fetch feature.
              </p>
              <div className="home-solution-reporting-button">
                <Link
                  to="solutions/esg-reporting"
                  className="home-solution-learn-btn"
                >
                  Learn More
                  <img
                    src={assets.icons.rightArrowIcon}
                    alt="updapt"
                    className="image-icon"
                    height="100%"
                    width="100%"
                  />
                </Link>
              </div>
              <div className="grey-circle"></div>
            </div>
          </div>
        </div>

        <div className="home-solution-analytics-wrapper">
          <div className="home-solution-analytics-content-wrapper">
            <div className="analytics-grey-circle"></div>
            <div className="home-solution-analytics-content">
              <div className="home-solution-analytics-badge">ANALYTICS</div>
              <h3 className="home-solution-analytics-title">
                {/* Use <span>Analytics</span> to Obtain Valuable Insights */}
                ESG Analytics and Reporting
              </h3>
              <p className="home-solution-analytics-description">
                Make strategic decisions, benchmark performance, and communicate
                more effectively with internal and external stakeholders with
                robust ESG analysis.
              </p>
              <div className="home-solution-analytics-button">
                <Link
                  to="solutions/esg-analytics"
                  className="home-solution-learn-btn"
                >
                  Learn More
                  <img
                    src={assets.icons.rightArrowIcon}
                    alt="updapt"
                    className="image-icon"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="home-solution-analytics-image-wrapper">
            <div className="home-solution-analytics-image">
              <img
                src={assets.images.homeAnalyticsImage}
                alt="this slowpoke moves"
                className="analytics-image"
                height="100%"
                width="100%"
              />
              <div className="analytics-blue-circle-1"></div>
              <div className="analytics-blue-circle-2"></div>
              <div className="analytics-blue-circle-3"></div>
              <div className="analytics-blue-circle-4"></div>
              <div className="analytics-blue-circle-5"></div>
            </div>
          </div>
        </div>

        {/*
      
      <div className="home-solution-grant-wrapper">
       
        <div className="home-solution-grant-image-wrapper">
          <div className="home-solution-grant-image">
            <img src={assets.images.grantImage} alt="updapt" className="image"/>
          </div>
        </div>
        <div className="home-solution-grant-content-wrapper">
          <div className="home-solution-grant-content">
            <div className="home-solution-grant-badge">GRANT MANAGEMENT</div>
            <h1 className="home-solution-grant-title">
            Enhance your Social Impact with Seamless <span>Grant Management</span>
            </h1>
            <p className="home-solution-grant-description">
            Streamline your grant management process and<br/> manage all your social projects in one place to drive<br/> measurable results.
            </p>
            <div className="home-solution-grant-button">
            <Link to="solutions/grant"  className="home-solution-learn-btn" >
            Learn More
            </Link>
              <img src={assets.icons.rightArrowIcon} alt="updapt"  className="image-icon"/>
            </div>
          </div>
        </div>
      </div> */}
      </div>
    </section>
  );
}

export default withRouter(Solution);
